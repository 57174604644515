<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="mytitle" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="content second_page_margin">
      <div class="input_content">
        <div class="currency">
          <div class="transfer_input_label" v-html="$t('message.transfer_currency_title')"></div>
          <van-dropdown-menu style="margin-top: 10px;">
            <van-dropdown-item style="width: 100px;background-color: unset;"
            v-model="currency_opt" :options="currency_options" @change='currency_opt_change'/>
          </van-dropdown-menu>
        </div>

        <div class="from">
          <div class="transfer_input_label" v-html="$t('message.transfer_from_account')"></div>
          <van-dropdown-menu style="margin-top: 10px;">
            <van-dropdown-item style="width: 100px;background-color: unset;"
            v-model="from_account_opt" :options="account_options" @change='from_opt_change'/>
          </van-dropdown-menu>
        </div>


        <div class="to">
          <div class="transfer_input_label" v-html="$t('message.transfer_to_account')"></div>
          <van-dropdown-menu style="margin-top: 10px;">
            <van-dropdown-item style="width: 100px;background-color: unset;"
            v-model="to_account_opt" :options="account_options" @change='to_opt_change'/>
          </van-dropdown-menu>
        </div>

        <div class="transfer_input_label" v-html="$t('message.transfer_amount_title')"></div>
        <van-field type="number" class="input_field" v-model="transfer_amount" label="" :placeholder="$t('message.transfer_amount_title')">
          <div class="page_flex page_flex_row" slot="right-icon">
            <div v-html="currency_options[currency_opt].text"></div>
            <div style="padding: 0 5px;">|</div>
            <div class="all_btn" v-html="$t('message.all_btn')" @click="all_btn"></div>
          </div>
        </van-field>
      </div>


      <div class="transfer_submit" style="margin: 30px 20px;">
        <div class="transfer_input_tips" v-html="$t('message.transfer_usable') + currency_options[currency_opt].usable + ' ' + currency_options[currency_opt].text"></div>
        <div class="primary_btn btn_shape" style="width: 100%;margin-top: 5px;" @click="do_transfer">{{$t('message.profile_transfer')}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import request from '@/utils/requests';
import { watch } from 'vue';
import { userWalletType,walletTransfer } from '@/api/trade';

export default {
  data() {
    return {
      wallet_type:{},
      all_currency:[],
      mytitle: this.$t('message.profile_transfer'),
      currency_options:[],
      currency_opt:1,
      account_options:[],
      from_account_opt:1,
      to_account_opt:1,

      total_quantity:0.0000,
      transfer_amount:'',

    }
  },
  name: 'Transfer',

  methods: {
    from_opt_change(value){
      console.log('from_opt_change:',value);

      this.currency_options = this.all_currency[value];
      this.currency_opt = 0;
      this.from_account_opt = value;

      console.log('currency_options:',this.currency_options);

    },
    to_opt_change(value){
      this.to_account_opt = value;
    },
    currency_opt_change(value){
      this.currency_opt = value;
    },
    do_transfer(){

    },
    all_btn(){
      this.transfer_amount = this.total_quantity;
    },
    async get_wallet_type() {
      const res = await userWalletType();
      if(res.code == 0){
        this.wallet_type = res.wallet_type_list;
        this.all_currency = res.currency_list;
        for(let i = 0;i < this.wallet_type.length;i++){
          let market = parseInt(this.wallet_type[i]);
          console.log('market:',market);
          let market_name = '';
          switch (market){
            case 1:
              market_name = this.$t('message.transfer_account_opt1');
              break;
            case 2:
              market_name = this.$t('message.transfer_account_opt2');
              break;
            case 3:
              market_name = this.$t('message.transfer_account_opt3');
              break;
            case 4:
              market_name = this.$t('message.transfer_account_opt4');
              break;
            default:
              break;
          }

          let item2 = {
            text: market_name, value: i,index: market
          }

          console.log('item2:',item2);

          this.account_options.push(item2);
          if( i == 0){
            this.currency_options = this.all_currency[0];
            this.currency_opt = 0;
            this.from_account_opt = 0;
            this.to_account_opt = 0;
          }
        }

        console.log('account_options:',this.account_options);
        console.log('currency_options:',this.currency_options);
      }
    },
    async do_transfer(){
      let from = this.account_options[this.from_account_opt].index;
      let to = this.account_options[this.to_account_opt].index;
      let symbol = this.currency_options[this.currency_opt].text;

      if(from == to){
        this.$toast(this.$t('message.error_paramter'));
        return;
      }

      const res = await walletTransfer(from,to,symbol,this.transfer_amount);
      if(res.code == 0){
        this.$toast(this.$t('message.opration_success'));
        setTimeout(function(){
          window.history.back();
        },1000);

      }
    },
  },
  created() {
    this.get_wallet_type();
  },
  destroyed() {
    clearInterval(this.timer)
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.wallet_type{
  .text_center();

  .wallet_qr{
    width: 250px;
  }

  .wallet_address{
    padding: 0 10px;
    font-size: 14px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
  }
}

.transfer_wallet_cp{
  .text_center();
  color: @high_primary_txt_color;
}

.input_content{
  padding: 10px 20px;

  .transfer_input_label{
    margin-top: 20px;
  }

  .transfer_input_tips{
    font-size: 12px;
  }
}

::v-deep .van-dropdown-menu__bar{
  background-color: unset;

  .van-dropdown-menu__title::after{
    border-color:transparent transparent @primary_txt_color @primary_txt_color;
  }
}

::v-deep .van-dropdown-menu__item{
  //align-items:unset;
  justify-content: unset;
  background: @white_bg;
  border-radius: 5px;

  .van-dropdown-menu__title{
    color: @sub_txt_color;
  }
}

.all_btn{
  padding: 0 10px;
  color: @primary_txt_color;
}


</style>
