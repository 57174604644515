<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="mytitle" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="content second_page_margin">
      <div class="currency">
        <van-dropdown-menu>
          <van-dropdown-item style="width: 100px;background-color: unset;" v-model="currency_opt" :options="currency_options" />
        </van-dropdown-menu>
      </div>

      <div class="wallet_type" style="margin-bottom: 10px;">
        <van-tabs type="card" v-model="wallet_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                  :color="wallet_active_color" :title-active-color="wallet_active_text_color" @click="walletOptChange">
          <van-tab :name="item.id" :title="item.type" v-for="item in wallet_list" :key="item.address">
            <img class="wallet_qr" :src="item.pic">
            <div class="wallet_address" v-html="item.address"></div>
          </van-tab>
        </van-tabs>
      </div>
      <div class="recharge_wallet_cp" @click="to_copy">{{$t('message.copy_btn')}}</div>
      <div class="input_content">
        <div class="recharge_input_label" v-html="$t('message.recharge_address_title')"></div>
<!--        <input class="input_field" type="text" autocomplete="new-password"-->
<!--               :placeholder="$t('message.recharge_address_title')" v-model="from_recharge_address"/>-->
        <van-field type="text" class="input_field" v-model="from_recharge_address" label="" :placeholder="$t('message.recharge_address_title')">
        </van-field>
        <div class="recharge_input_label" v-html="$t('message.recharge_quantity_title')"></div>
<!--        <input class="input_field" type="number" autocomplete="new-password"-->
<!--               :placeholder="$t('message.recharge_quantity_title')" v-model="recharge_quantity"/>-->
        <van-field type="number" class="input_field" v-model="recharge_quantity" label="" :placeholder="$t('message.recharge_quantity_title')">
          <div slot="right-icon" v-html="currency_options[currency_opt].text"></div>
        </van-field>


      </div>

      <div class="recharge_submit" style="margin: 30px 20px;">
        <div class="primary_btn btn_shape" style="width: 100%;" @click="do_recharge">{{$t('message.profile_recharge')}}</div>
      </div>

      <div style="height: 20px;"></div>

    </div>
  </div>
</template>

<script>
import request from '@/utils/requests';
import { watch } from 'vue';
import { rechargeWallet,userRecharge } from '@/api/trade';

export default {
  data() {
    return {
      mytitle: this.$t('message.profile_recharge'),
      currency_options:[
        { text: this.$t('message.recharge_currency_opt1'), value: 0 },
      ],
      currency_opt:0,
      wallet_active:'0',
      to_address_id:0,
      wallet_active_color:'#22aa66',
      wallet_active_text_color: 'white',
      wallet_list:[],
      from_recharge_address:'',
      recharge_quantity:'',
    }
  },
  name: 'Recharge',

  methods: {
    walletOptChange(index, title) {
      this.wallet_active = index;
    },
    to_copy(){
      let that = this;
      let wallet_address = '';
      for (let i = 0, len = this.wallet_list.length;i < len; i++){
        let wallet = this.wallet_list[i];
        if (this.wallet_active == wallet.type){
          wallet_address = wallet.address;
          break;
        }
      }

      // console.log('to cp..');
      navigator.clipboard.writeText(
        wallet_address)
        .then(()=>{
          // console.log('ok..');
          that.$toast(that.$t('message.copy_ok'));
        })
        .catch(
          (error)=>{
            // console.log('error..');
          }
        )
    },
    do_recharge(){
      this.recharge();

    },
    async recharge_wallet(){
      const wallet_info = await rechargeWallet()
      console.log(wallet_info);
      if(wallet_info.code == 0){
        this.wallet_list = wallet_info.wallet_list;
        this.wallet_active = this.wallet_list[0].id;
      }
    },
    async recharge(){
      const recharge_info = await userRecharge(this.recharge_quantity,
      this.from_recharge_address,this.wallet_active)
      console.log(recharge_info);
      if(recharge_info.code == 0){
        this.$toast(this.$t('message.opration_success'));
        setTimeout(function(){
          window.history.back();
        },1000);
      }
    }
  },
  created() {
    this.recharge_wallet();
  },
  destroyed() {
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.wallet_type{
  .text_center();

  .wallet_qr{
    margin-top: 10px;
    width: 250px;
  }

  .wallet_address{
    padding: 0 10px;
    font-size: 14px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
  }
}

.recharge_wallet_cp{
  .text_center();
  color: @positive_color;
}

.input_content{
  padding: 10px 20px;

  .recharge_input_label{
    margin-top: 10px;
  }
}

::v-deep .van-dropdown-menu__bar{
  background-color: unset;
}

::v-deep .van-dropdown-menu__title::after{
  border-color:transparent transparent @primary_txt_color @primary_txt_color
}

</style>
