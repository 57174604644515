<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="mytitle" left-arrow @click-left="$router.go(-1)" fixed />
    <div class="content second_page_margin">
      <div style="height: 1px"></div>
      <div class="account_item" v-for="item in account_list" :key="item.symbol">
        <div class="currency_block">
          <div class="symbol" v-html="item.symbol"></div>
          <div class="value" v-html="'≈ ' + parseFloat(item.balance).toFixed(4) + ' ' + $t('message.account_unit_opt1')"></div>
        </div>

        <div class="account_detail">
          <div class="detail_item">
            <div class="title" v-html="$t('message.account_available_title')"></div>
            <div class="value" v-html="parseFloat(item.usable).toFixed(4)">
            </div>
          </div>

          <div class="detail_item">
            <div class="title" v-html="$t('message.account_progress_title')"></div>
            <div class="value" v-html="parseFloat(item.frozen).toFixed(4)">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/requests';
import { watch } from 'vue';
import { userWallet } from '@/api/trade';

export default {
  data() {
    return {
      mytitle: '',
      market:1,
      pos: 0,
      account_list:[],
    }
  },
  name: 'Account',

  methods: {
    async userWallet(){
      const res = await userWallet(this.market);
      if(res.code == 0){
        this.account_list = res.wallet_list;
      }
    }
  },
  created() {
    this.mytitle = this.$route.query.title;
    this.pos = this.$route.query.pos;
    console.log('pos:'+typeof(this.pos));
    switch (this.pos){
      case '0':
        console.log('1');
        this.market = 100;
        break;
      case '1':
        console.log('2');
        this.market = 2;
        break;
      case '2':
        console.log('3');
        this.market = 3;
        break;
      default:
        console.log('other');
        break;
    }
  },
  destroyed() {
  },
  mounted(){
    this.userWallet();
  }
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.account_item{

  background: linear-gradient(90deg, #2222aa, #2222ff);
  margin: 20px;
  border-radius: 10px;
  color: @op_primary_txt_color;
  padding: 20px 20px 10px 20px;

  .currency_block{
    .unit{
      font-size: 18px;
    }

    .value{
      margin-top: 10px;
    }
  }

  .account_detail{
    .page_flex();
    .page_flex_row();

    .detail_item{
      margin-top: 30px;
      width: 50%;

      .title{
        font-size: 14px;
      }

      .value{
        margin-top: 10px;
      }
    }
  }
}

</style>
