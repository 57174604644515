<template>
  <div class="color_page_bg all_page">
    <van-nav-bar :title="mytitle" left-arrow @click-left="$router.go(-1)" fixed/>
    <div class="content second_page_margin">
      <div class="currency">
        <van-dropdown-menu>
          <van-dropdown-item style="width: 100px;background-color: unset;" v-model="currency_opt" :options="currency_options" />
        </van-dropdown-menu>
      </div>

      <div class="wallet_type" style="margin-bottom: 10px;">
        <van-tabs type="card" v-model="wallet_active" background="rgba(0,0,0,0)" title-inactive-color="#888888"
                  :color="wallet_active_color" :title-active-color="wallet_active_text_color" @click="walletOptChange">
          <van-tab :name="item.type" :title="item.type" v-for="item in wallet_list" :key="item.type">
          </van-tab>
        </van-tabs>
      </div>

      <div class="input_content">
        <div class="withdrawal_input_label" v-html="$t('message.withdrawal_extractable_quantity_title')"></div>
        <van-field type="number" class="input_field" v-model="total_quantity" label="" readonly
                   :placeholder="$t('message.withdrawal_extractable_quantity_title')">
          <div slot="right-icon" v-html="currency_options[currency_opt].text"></div>
        </van-field>


        <div class="withdrawal_input_label" v-html="$t('message.withdrawal_address_title')"></div>
        <van-field type="text" class="input_field" v-model="withdrawal_address" label="" :placeholder="$t('message.recharge_address_title')">
        </van-field>
        <div class="withdrawal_input_tips" v-html="$t('message.withdrawal_address_tips')"></div>

        <div class="withdrawal_input_label" v-html="$t('message.withdrawal_amount_title')"></div>
        <van-field type="number" class="input_field" v-model="withdrawal_amount" label="" :placeholder="$t('message.withdrawal_amount_title')">
          <div class="page_flex page_flex_row" slot="right-icon">
            <div v-html="currency_options[currency_opt].text"></div>
            <div style="padding: 0 10px;">|</div>
            <div class="all_btn" v-html="$t('message.all_btn')" @click="all_btn"></div>
          </div>
        </van-field>
        <div class="withdrawal_input_tips" v-html="$t('message.withdrawal_fee') + (withdrawal_fee_rate * withdrawal_amount).toFixed(2)"></div>


        <div class="withdrawal_input_label" v-html="$t('message.withdrawal_pwd_title')"></div>
        <van-field type="password" class="input_field" v-model="withdrawal_pwd" label=""
                   :placeholder="$t('message.withdrawal_pwd_title')">

        </van-field>
      </div>


      <div class="withdrawal_submit" style="margin: 30px 20px;">
        <div class="withdrawal_input_tips" v-html="$t('message.withdrawal_amount_tps') + withdrawal_amount_real + ' ' + currency_options[currency_opt].text"></div>
        <div class="primary_btn btn_shape" style="width: 100%;margin-top: 5px;" @click="do_withdrawal">{{$t('message.profile_withdrawal')}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import request from '@/utils/requests';
import { watch } from 'vue';
import { userWithdrawal,withdrawalInfo } from '@/api/trade';

export default {
  data() {
    return {
      mytitle: this.$t('message.profile_withdrawal'),
      currency_options:[
        { text: this.$t('message.withdrawal_currency_opt1'), value: 0 },
      ],
      currency_opt:0,
      wallet_active:'ERC_20',
      wallet_active_color:'#22aa66',
      wallet_active_text_color: 'white',
      wallet_list:[
        {type:'ERC_20'},
        {type:'TRC_20'},
      ],
      withdrawal_address:'',
      total_quantity:0.0000,
      withdrawal_amount:'',
      withdrawal_fee_rate:0,
      withdrawal_amount_real:0,
      withdrawal_pwd:''

    }
  },
  name: 'Withdrawal',

  methods: {
    walletOptChange(index, title) {
      this.wallet_active = index;
    },
    do_withdrawal(){
      console.log('total_quantity:'+this.total_quantity+',withdrawal_amount:'+this.withdrawal_amount);
      if((this.total_quantity - this.withdrawal_amount) < 0){
        console.log('1');
        this.$toast(this.$t('message.withdrawal_amount_err'));
        return;
      }
      this.user_withdrawal();
    },
    all_btn(){
      this.withdrawal_amount = this.total_quantity;
    },
    async user_withdrawal(){
      const with_info = await userWithdrawal(this.withdrawal_amount,
      this.withdrawal_address,this.wallet_active,this.withdrawal_pwd)
      console.log(with_info);
      if(with_info.code == 0){
        this.$toast(this.$t('message.opration_success'));
        setTimeout(function(){
          window.history.back();
        },1000);
      }
      else{
        this.$toast(with_info.info);
      }
    },
    async withdrawal_info(){
      const with_info = await withdrawalInfo()
      console.log(with_info);
      if(with_info.code == 0){
        this.total_quantity = parseFloat(with_info.balance);
        this.withdrawal_fee_rate = parseFloat(with_info.fee);
      }
      else{
        this.$toast(with_info.info);
      }
    }
  },
  async created() {
    this.withdrawal_info();
  },
  destroyed() {
    clearInterval(this.timer)
  },
}

</script>

<style lang="less" scoped>
@import '../../css/pagestyle.less';

.wallet_type{
  .text_center();

  .wallet_qr{
    width: 250px;
  }

  .wallet_address{
    padding: 0 10px;
    font-size: 14px;
    margin-top: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: normal;
    white-space: normal;
  }
}

.withdrawal_wallet_cp{
  .text_center();
  color: @high_primary_txt_color;
}

.input_content{
  padding: 10px 20px;

  .withdrawal_input_label{
    margin-top: 20px;
  }

  .withdrawal_input_tips{
    font-size: 12px;
  }
}

::v-deep .van-dropdown-menu__bar{
  background-color: unset;
}

::v-deep .van-dropdown-menu__title::after{
  border-color:transparent transparent @primary_txt_color @primary_txt_color
}

.all_btn{
  padding: 0 10px;
  color: @primary_txt_color;
}

</style>
